// in src/contracts.js
import * as React from "react";
import { cloneElement, useMemo, memo, Children, Fragment  } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import lodashGet from 'lodash/get';
// import Button from '@material-ui/core/Button';
import ResetViewsButton from './ResetViewsButton';
import {  
	TabbedShowLayout,
	Tab,
	DateField,
	Datagrid,
	DateInput,
	Filter,
	List,
	ReferenceInput,
	RichTextField,
	SearchInput,
	SelectInput,
	Show,
	SimpleShowLayout,  
	TextField,
	useListContext,
	TopToolbar,
	ExportButton,
	ShowButton,
	sanitizeListRestProps
} from 'react-admin';


const ListActions = (props) => {
	const {
			className,
			exporter,
			filters,
			maxResults,
			...rest
	} = props;
	const {
			currentSort,
			resource,
			displayedFilters,
			filterValues,
			hasCreate,
			basePath,
			selectedIds,
			showFilter,
			total,
	} = useListContext();
	return (
			<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
					{filters && cloneElement(filters, {
							resource,
							showFilter,
							displayedFilters,
							filterValues,
							context: 'button',
					})}
					<ExportButton
							disabled={total === 0}
							resource={resource}
							sort={currentSort}
							filterValues={filterValues}
							maxResults={maxResults}
					/>
			</TopToolbar>
	);
};

const useContractListActionToolbarStyles = makeStyles({
	toolbar: {
			alignItems: 'center',
			display: 'flex',
			marginTop: -1,
			marginBottom: -1,
	},
});



const ContractListActionToolbar = ({ children, ...props }) => {
	const classes = useContractListActionToolbarStyles();
	return (
			<div className={classes.toolbar}>
					{Children.map(children, button => cloneElement(button, props))}
			</div>
	);
};


const ContractFilter = props => (
	<Filter {...props}>
			<SearchInput source="q" alwaysOn />
			<ReferenceInput source="startingDate" reference="contracts">
					<DateInput  />
			</ReferenceInput>
			<ReferenceInput source="endDate" reference="contracts">
					<DateInput  />
			</ReferenceInput>
			{/* <ReferenceInput source="partyAinfo" reference="contracts">
					<SelectInput  optionText="Company name" />
			</ReferenceInput> */}
	</Filter>
);

const ContractPanel = ({ id, record, resource }) => (
	<div dangerouslySetInnerHTML={{ __html: record.project }} />
);

const UrlField = ({ record, source }) => <a href={record.contractPDFURL} target="_blank">PDF</a>;

export const ContractList = props => (
	<List {...props}  filters={<ContractFilter />} actions={<ListActions />}>
		<Datagrid expand={ContractPanel} optimized>
			<TextField label="Party A Company" source="partyAinfo.company" />
			<TextField label="Party A Type" source="partyAinfo.companyType" />
			<TextField label="Party A Name" source="partyAinfo.name" />
		</Datagrid>
	</List>
);

const classes = makeStyles({
	root: { width: '50%', margin: 'auto' },
	spacer: { height: 20 },
	invoices: { margin: '10px 0' },
});


export const ContractShow = (props) => (
	<Show {...props}>
		<TabbedShowLayout>
			<Tab label="General Info">
				<SimpleShowLayout className={classes.root}>
					<h3>Party A</h3>
					<TextField label="Company" source="partyAinfo.company" />
					<TextField label="Company Type" source="partyAinfo.companyType" />
					<TextField label="Country" source="partyAinfo.country" />
					<TextField label="Name" source="partyAinfo.name" />
					<TextField label="Position" source="partyAinfo.position" />
				</SimpleShowLayout>
			</Tab>
			<Tab label="Project Info">
				<SimpleShowLayout>
					<h3>Project General Info</h3>
					<RichTextField label="Project" source="project" />
					<DateField label="Starting date" source="startingDate" />
					<DateField label="End date" source="endDate" />
					<TextField label="Duration (year)" source="durationProject" />
					<TextField label="Days prior notice" source="daysPriorNotice" />
					<RichTextField label="Information Included" source="informationIncluded" />
					<TextField label="Days after declaration Dispute" source="daysAfterDeclarationDispute" />
					<TextField label="Court/Tribunal Jurisdiction" source="courtJurisdiction" />
					<TextField label="Applicable Law" source="applicableLaw" />
				</SimpleShowLayout>
			</Tab>
			<Tab label="Contact Info">
				<SimpleShowLayout>
					<h3>Party A</h3>
					<TextField label="Name" source="partyAcontact.name" />
					<TextField label="Mail" source="partyAcontact.email" />
				</SimpleShowLayout>
			</Tab>
			<Tab label="Signature Info">
				<SimpleShowLayout>
					<h3>Party A</h3>
					<TextField label="Name" source="partyAsignature.name" />
					<TextField label="Position" source="partyAsignature.position" />
					<TextField label="Mail" source="partyAsignature.email" />
					<TextField label="Phone" source="partyAsignature.phone" />
				</SimpleShowLayout>
			</Tab>
		</TabbedShowLayout>
	</Show>
);