import * as React from "react";
import { BrowserRouter } from 'react-router-dom';

import authProvider from './authProvider';
import { fetchUtils, Admin, Resource } from 'react-admin';

import simpleRestProvider from 'ra-data-simple-rest';

import createHistory from 'history/createBrowserHistory';

import { ContractList, ContractShow } from './contracts';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }

  // url = 'http://localhost:3001/users'
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);

const history = createHistory();

class App extends React.Component {

  render() {
      return <BrowserRouter>
        <Admin
          authProvider={authProvider}
          dataProvider={dataProvider}
          history={history}>
          <Resource name="contracts" show={ContractShow} list={ContractList} />
        </Admin>
      </BrowserRouter>
    }
}

require('dotenv').config();

export default App;